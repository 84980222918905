import React, { useState } from 'react';
import logo from './assets/images/kadoussou.png';
import bissap from "./assets/images/bissap.jpg"
import image2 from "./assets/images/image2.jpg"
import citronnelle from "./assets/images/c1.jpg"
import image4 from "./assets/images/c11.jpg"
import pgingembre from "./assets/images/g11.JPG"
import image3 from "./assets/images/pgingembre2.jpg"
import insta from "./assets/images/insta.png"
import './App.css';
import { motion } from 'framer-motion';

function App() {


  const [activeIndex, setActiveIndex] = useState(0);
  const menuItems = ['Bissap', 'Citronnelle', 'Gingembre'];


  return (
    <div className="App">
      <header className="App-header">
        <div className='topp'>
          <img src={logo} className="App-logo"  />
        </div>
        <div className='menu'> 
        {menuItems.map((item, index) => (
            <motion.div
              key={index}
              onClick={() => {setActiveIndex(index); console.log(index)}}
              style={{
                padding: '10px 20px',
                cursor: 'pointer',
                color: activeIndex === index ? 'green' : 'gray',
                position: 'relative',
                fontWeight: 800
              }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
            >
              {item}
              {/* Barre de soulignement animée */}
              {activeIndex === index && (
                <motion.div
                  layoutId="underline"
                  style={{
                    position: 'absolute',
                    bottom: -5, // ajuste la distance par rapport au texte
                    left: 0,
                    width: '100%', // occupe toute la largeur de l'élément texte
                    height: '2px',
                    backgroundColor: 'green',
                  }}
                  transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                />
              )}
            </motion.div>
          ))}

          
        </div>
        {
          activeIndex === 0 && <div>
            <div className='portfollio'>
            <div style={{
                height: 45, 
                width: 45, 
                backgroundColor: "rgb(195, 208, 67)", 
                borderRadius: 25, 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                fontWeight: 800
             }} >B</div>
            <div>
            <p style={{
                  marginLeft: 5, 
            
                }}>Bissap pur et intense</p>
            </div>
            </div>

            <div className='twoimages'>
                    <div>
                      <img src={bissap} className="bissap" />
                    </div>
                    <div style={{marginLeft: 4}}>
                      <img src={image2} className="bissap" />
                    </div>
            </div>

            <div style={{
              paddingLeft: 15, 
              paddingRight: 15, 
              display: "flex", 
              flexDirection: "column", 
              alignItems: "flex-start"
            }}>
             <div style={{
              textAlign: "left", marginTop: 8
             }}> <strong>1-</strong>  Vitamines : Calcium, Fer, de la vitamine C et B12.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>2-</strong> Anti inflammatoire.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}> <strong>3-</strong> Proprietes antalgiques, le jus de bissap permet de relaxer les muscles.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>4-</strong> Boisson énergétique de source d’énergie naturelle.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>5-</strong> Excellent pour la digestion.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>6-</strong> Favorise la santé des cheveux.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>7-</strong> Combat l’hypertension, l’anémie et les problèmes de la peau.</div>
              </div>
          </div>
        }

{
          activeIndex === 1 && <div>
            <div className='portfollio'>
            <div style={{
                height: 45, 
                width: 45, 
                backgroundColor: "rgb(195, 208, 67)", 
                borderRadius: 25, 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                fontWeight: 800
             }} >C</div>
            <div>
            <p style={{
                  marginLeft: 5, 
            
                }}>Citronnelle tranformée</p>
            </div>
            </div>

            <div className='twoimages'>
                    <div>
                      <img src={citronnelle} className="bissap" />
                    </div>
                    <div style={{marginLeft: 4}}>
                      <img src={image4} className="bissap" />
                    </div>
            </div>

            <div style={{
              paddingLeft: 15, 
              paddingRight: 15, 
              paddingBottom: 20,
              display: "flex", 
              flexDirection: "column", 
              alignItems: "flex-start"
            }}>
             <div style={{
              textAlign: "left", marginTop: 8
             }}> <strong>1-</strong>  Combat les troubles urinaires.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>2-</strong> Antibacterien et apaise les personnes souffrant de diarrhée.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}> <strong>3-</strong> Antipaludeen reconnu dans les régions d’Afrique occidentale.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>4-</strong> Favorise le sommeil.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>5-</strong> Traitement des calculs biliaires : actions stimulantes sur la fiction biliaire.</div>
            <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>6-</strong> Nettoie 100% le rein de toutes les toxines.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>7-</strong> Contribue au rajeunissement des cellules de la peau : lutte contre les rides du visage, le vieillissement de la peau et le blanchissement précoce des cheveux.</div>
              <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>8-</strong> vous protège contre les AVC.</div>
              </div>
             
          </div>
        }

{
          activeIndex === 2 && <div>
            <div className='portfollio'>
            <div style={{
                height: 45, 
                width: 45, 
                backgroundColor: "rgb(195, 208, 67)", 
                borderRadius: 25, 
                display: "flex", 
                alignItems: "center", 
                justifyContent: "center", 
                fontWeight: 800
             }} >G</div>
            <div>
            <p style={{
                  marginLeft: 5, 
            
                }}>Poudre de Gingembre</p>
            </div>
            </div>

            <div className='twoimages'>
                    <div>
                      <img src={pgingembre} className="bissap" />
                    </div>
                    <div style={{marginLeft: 4}}>
                      <img src={image3} className="bissap" />
                    </div>
            </div>

            <div style={{
              paddingLeft: 15, 
              paddingRight: 15, 
              paddingBottom: 20,
              display: "flex", 
              flexDirection: "column", 
              alignItems: "flex-start"
            }}>
             <div style={{
              textAlign: "left", marginTop: 8
             }}> <strong>1-</strong> Soulager différents maux tes que les rhumatismes, la fatigue, les nausées, le rhume, les maux de tête et surtout les problèmes digestifs.</div>
               <div style={{
              textAlign: "left", 
              marginTop: 4
             }}><strong>2-</strong> Riche en minéraux : manganèse, phosphore, magnésium et aussi calcium, sodium et fer. Il contient également des vitamines : B3, B1 et B2.</div>

            </div>
             
          </div>
        }

        <div style={{
          alignItems: "flex-start", 
          display: "flex", 
          flexDirection: "column", 
          paddingTop: 15, 
          paddingRight: 15, 
          paddingLeft: 15, 
          paddingBottom: 25
        }}>
          <div>
            Contactez-nous : 
          </div>
          <div>
            Mail : <a href='mailto:contacts@sofaprol.com'>contacts@sofaprol.com</a>
          </div>
          <div> Tel: 074 19 11 91 </div>

        </div>

        <div style={{
             display: "flex", 
             alignItems: "flex-start",
             justifyContent: "center",
             paddingBottom: 15, 
             flexDirection: "column", 
             paddingRight: 20, 
             paddingLeft: 20
        }}>
          <div style={{
            fontWeight: 800
          }}>Conseils de préparation :</div>
          <div style={{
              display: "flex", 
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column", 
              textAlign:"left"
          }}>
          Faire bouillir de l’eau et laisser infuser pendant 5-10 min un sachet de tisane dans une tasse d’au moins 300 ml d’eau. Boire chaud ou tiède.
          </div>
        </div>

        <div style={{
            display: "flex", 
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 15, 
            flexDirection: "column", 
            paddingRight: 20, 
            paddingLeft: 20
          }}>
            <div> 
              <a href='https://www.instagram.com/produitskadoussou_gabon?igsh=aDd3M2o5ZTdvcnlw'><img src={insta} style={{width: 75}} /></a>
            
            </div>
            <div>
              Accédez à notre page Instagram en cliquant sur l'icône 
            </div>
        </div>

       

      </header>
    </div>
  );
}

export default App;

